import { AxiosError } from 'axios';

export type ErrorPayload = {
  messages?: string[];
  message?: string;
};

export default async function handleError(
  err: AxiosError<ErrorPayload>,
  customFallbackMessage?: string
): Promise<string> {
  return Promise.reject(
    err?.response?.data?.messages?.[0] ??
      err?.response?.data?.message ??
      customFallbackMessage ??
      'Something went wrong'
  );
}
